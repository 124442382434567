import PropTypes from 'prop-types'
import { DragSource, DropTarget } from 'react-dnd'

import BeatPaletteItem from './BeatPaletteItem'

const beatDragSource = {
  beginDrag(props) {
    props.selectBeat(props.id);

    return {
      id: props.id,
      originalIndex: props.findBeat(props.id).index
    };
  },

  endDrag(props, monitor) {
    const { id: droppedId, originalIndex } = monitor.getItem();
    const didDrop = monitor.didDrop();

    if (!didDrop) {
      props.moveBeat(droppedId, originalIndex);
    }
  }
};

const beatDropTarget = {
  canDrop() {
    return true;
  },

  hover(props, monitor, component) {
    const { id: draggedId } = monitor.getItem();
    const { id: overId } = props;

    if (draggedId !== overId) {
      const { index: overIndex } = props.findBeat(overId);
      props.moveBeat(draggedId, overIndex);
    }
  },
};

function collectBeatDrag(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
}

function collectBeatDrop(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  };
}

export class _Beat extends React.Component {
  constructor(props) {
    super(props);

    this.markTouchStart = this.markTouchStart.bind(this);
    this.markTouchMove = this.markTouchMove.bind(this);
    this.checkForPress = this.checkForPress.bind(this);
    this.selectSelf = this.selectSelf.bind(this);
    this.editSelf = this.editSelf.bind(this);
    this.renderSingleResolution = this.renderSingleResolution.bind(this);
    this.renderResolutions = this.renderResolutions.bind(this);
  }

  markTouchStart(event) {
    this.touchStartTime = (new Date()).getTime();
    this.didDrag = false;
  }

  markTouchMove(event) {
    this.didDrag = true;
  }

  checkForPress(event) {
    if (!this.didDrag && (new Date()).getTime() - this.touchStartTime > 500) {
      event.stopPropagation();
      this.props.editBeat(this.props.id);
    }
  }

  selectSelf(event) {
    event.stopPropagation();
    this.props.selectBeat(this.props.id);
  }

  editSelf(event) {
    event.stopPropagation();
    this.props.editBeat(this.props.id);
  }

  renderTransition() {
    return <div className={`beat-transition transition-${this.props.transition}`}/>;
  }

  renderSingleResolution(resolution, key) {
    return <div className={`beat-resolution resolution-${resolution.direction}`} key={key}/>;
  }

  renderResolutions() {
    const resolutions = this.props.resolutions;

    if (resolutions.length === 1) {
      return this.renderSingleResolution(resolutions[0]);
    } else {
      const resolutionsByDirection = _.groupBy(this.props.resolutions, 'direction');

      if (resolutions.length === 2 && resolutionsByDirection.up && resolutionsByDirection.down) {
        return (
          <div className="resolutions-superimpose">
            {this.renderSingleResolution(resolutionsByDirection.up[0])}
            {this.renderSingleResolution(resolutionsByDirection.down[0])}
          </div>
        );
      } else {
        const directionStacks = ['up', 'lateral', 'down'].map((direction) => {
          const directionResolutions = resolutionsByDirection[direction];
          if (!directionResolutions) {
            return null;
          }

          const renderedResolutions = directionResolutions.map(this.renderSingleResolution);

          return (
            <div className="resolutions-stack" key={direction}>
              {renderedResolutions}
            </div>
          );
        });

        return <div>{directionStacks}</div>;
      }
    }
  }

  render () {
    const { connectDragSource, isDragging, connectDropTarget, isOver, scaleFactor } = this.props;

    const style = {
      marginTop: `${this.props.y}px`,
      opacity: (isDragging ? 0 : 1)
    };

    let containerClass = `beat-container scale-${scaleFactor}`;
    if (this.props.isSelected) {
      containerClass += " selected";
    }
    if (this.props._pending) {
      containerClass += " pending";
    }

    if (this.props.editable) {
      return connectDropTarget(
        connectDragSource(
          <div
            className={containerClass}
            style={style}
            onClick={this.selectSelf}
            onDoubleClick={this.editSelf}
            onTouchStart={this.markTouchStart}
            onTouchMove={this.markTouchMove}
            onTouchEnd={this.checkForPress}
          >
            <div className="beat">
              <div className={`beat-icon ${this.props.type}`}>
              </div>

              <p className="beat-id">{this.props.title}</p>
              <hr/>
              <p className="beat-description">{this.props.description}</p>
            </div>
            {this.renderTransition()}
            <div className="beat-resolutions">
              {this.renderResolutions()}
            </div>
          </div>,
          { dropEffect: 'move' }
        )
      );
    } else {
      return (
        <div
          className={containerClass}
          style={style}
        >
          <div className="beat">
            <div className={`beat-icon ${this.props.type}`}>
            </div>

            <p className="beat-id">{this.props.title}</p>
            <hr/>
            <p className="beat-description">{this.props.description}</p>
          </div>
          <div className="beat-resolutions">
            {this.renderResolutions()}
          </div>
        </div>
      );
    }
  }
}

_Beat.jsonProps = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  resolutions: PropTypes.arrayOf(
    PropTypes.shape({
      direction: PropTypes.string.isRequired
    }),
  ).isRequired,
  transition: PropTypes.string,
  _pending: PropTypes.bool
};

_Beat.propTypes = Object.assign({}, _Beat.jsonProps, {
  index: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
  findBeat: PropTypes.func.isRequired,
  moveBeat: PropTypes.func.isRequired,
  selectBeat: PropTypes.func.isRequired,
  editBeat: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
  scaleFactor: PropTypes.number.isRequired,
  touchStartTime: PropTypes.number,
  didDrag: PropTypes.bool
});

window.Beat = DropTarget(["Beat", "BeatPaletteItem"], beatDropTarget, collectBeatDrop)(
  DragSource("Beat", beatDragSource, collectBeatDrag)(_Beat)
);

export default Beat;