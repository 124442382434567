import PropTypes from 'prop-types'

import ResolutionSelector from './ResolutionSelector'
import TransitionSelector from './TransitionSelector'

// const RESOLUTION_TYPES = ['freefloating'];
const RESOLUTION_DIRECTIONS = ['down', 'lateral', 'up'];
const TRANSITION_ICONS = [
  {icon:'break', description:'The following scene shifts not only to another time and/or place, but also to a new or different plot thread.'},
  {icon:'continuation', description:'The following scene shows the focus character from the previous scene still pursuing that scene’s goal.'},
  {icon:'flashback', description:'The following scene takes place at an earlier time than the previous scene.'},
  {icon:'flash-forward', description:'The following scene departs from the story’s main timeline to a moment in its future.'},
  {icon:'meanwhile', description:'The following scene happens simultaneously with, or immediately after, the previous scene, but its action is not a consequence or continuation of the previous scene.'},
  {icon:'outgrowth', description:'The following scene has a new goal that is a direct consequence of the previous scene.'},
  {icon:'return', description:'Concludes a Flashback scene, resuming the previously established timeline.'},
  {icon:'rhyme', description:'A Break transition that unites the following and previous scenes by some common sensory cue.'},
  {icon:'turn', description:'The following scene shows the focus character from the previous scene pursuing a new goal.'},
  {icon:'viewpoint', description:'The following scene centers on a new focus or viewpoint character for the first time.'}
];

class BeatConfigurationModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      description: (this.props.mode === 'add' ? '' : this.props.beat.description),
      resolutions: (this.props.beat.resolutions || []).concat(),
      transition: (this.props.beat.transition)
    };

    this.getTitle = this.getTitle.bind(this);
    this.getResolutionSelected = this.getResolutionSelected.bind(this);
    this.descriptionChanged = this.descriptionChanged.bind(this);
    this.setResolutionSelected = this.setResolutionSelected.bind(this);
    this.setTransitionSelected = this.setTransitionSelected.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
    this.cancel = this.cancel.bind(this);
    this.renderDeleteButton = this.renderDeleteButton.bind(this);
  }

  getTitle() {
    if (this.props.mode === 'add') {
      return `New ${_.capitalize(this.props.beat.type)} Beat`;
    } else {
      return `Editing ${_.capitalize(this.props.beat.type)} Beat`;
    }
  }

  getResolutionSelected(direction) {
    const beat = _.find(this.state.resolutions, (resolution) => {
      return (resolution.direction === direction);
    });

    return (beat !== undefined);
  }

  descriptionChanged(event) {
    this.setState({ description: event.target.value });
  }

  setResolutionSelected(direction, selected) {
    if (selected) {
      if (this.getResolutionSelected(direction)) {
        return;
      }

      this.setState({
        resolutions: this.state.resolutions.concat({
          direction: direction
        })
      });
    } else {
      this.setState({
        resolutions: _.reject(this.state.resolutions, (resolution) => {
          return (resolution.direction === direction);
        })
      });
    }
  }

  setTransitionSelected(icon, selected) {
    if (selected) {
      this.setState({ transition: icon });
    } else {
      this.setState({ transition: '' });
    }
  }

  cancel() {
    if (this.props.mode === 'add') {
      this.props.removeBeat(this.props.beat.id);
    }

    this.props.dismissBeatConfiguration();
  }

  saveChanges() {
    this.props.updateBeat(this.props.beat.id, {
      description: this.state.description,
      resolutions: this.state.resolutions,
      transition: this.state.transition,
      _pending: false
    });

    this.props.dismissBeatConfiguration();
  }

  renderResolutions() {
    const resolutions = Array.prototype.concat.apply([],
      RESOLUTION_DIRECTIONS.map((direction) => {
        const selected = this.getResolutionSelected(direction);

        return (
          <ResolutionSelector
            key={`${direction}_beat`}
            direction={direction}
            isSelected={selected}
            setSelected={this.setResolutionSelected.bind(this, direction)}
          />
        );
      })
    );

    return <div className="beat-resolution-selector-grid">{resolutions}</div>;
  }

  renderTransitions() {
    const icons = Array.prototype.concat.apply([],
      TRANSITION_ICONS.map((transition) => {
        const selected = (this.state.transition === transition['icon']);

        return (
          <TransitionSelector
            key={`${transition['icon']}_transition`}
            icon={transition['icon']}
            description={transition['description']}
            isSelected={selected}
            setSelected={this.setTransitionSelected.bind(this, transition['icon'])}
          />
        );
      })
    );

    return <div className="beat-resolution-selector-grid">{icons}</div>;
  }

  renderDeleteButton() {
    if (this.props.mode !== 'edit') {
      return null;
    }

    const deleteBeat = () => {
      if (!confirm(`Are you sure you want to delete the beat ${this.state.description}?`)) {
        return;
      }

      this.props.removeBeat(this.props.beat.id);
      this.props.dismissBeatConfiguration();
    };

    return (
      <button className="btn btn-danger btn-sm" onClick={deleteBeat}>Delete</button>
    );
  }

  render() {
    return (
      <div className="modal fade in" style={{display: "block"}}>
        <div className="modal-dialog modal-resolution-selector" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">{this.getTitle()}</h4>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="beat_description">Short Description</label>
                <textarea
                  className="form-control"
                  id="beat_description"
                  value={this.state.description}
                  onChange={this.descriptionChanged}
                  autoFocus={true}
                />
              </div>

              <div className="form-group">
                <label>Select beat resolution arrow(s):</label>
                {this.renderResolutions()}
                <label>Select a transition if needed:</label>
                {this.renderTransitions()}
              </div>
            </div>
            <div className="modal-footer">
              <div className="row">
                <div className="col-sm-3 text-sm-left">
                  {this.renderDeleteButton()}
                </div>
                <div className="col-sm-9">
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <button type="button" className="btn btn-secondary btn-sm" onClick={this.cancel}>Cancel</button>
                    </li>
                    <li className="list-inline-item">
                      <button type="button" className="btn btn-primary btn-sm" onClick={this.saveChanges}>Save changes</button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

BeatConfigurationModal.propTypes = {
  beat: PropTypes.shape(Beat.jsonProps).isRequired,
  mode: PropTypes.string.isRequired,
  dismissBeatConfiguration: PropTypes.func.isRequired,
  updateBeat: PropTypes.func.isRequired,
  removeBeat: PropTypes.func.isRequired,
};

export default BeatConfigurationModal;