import PropTypes from 'prop-types'

class ResolutionSelector extends React.Component {
  constructor(props) {
    super(props);

    this.toggleSelected = this.toggleSelected.bind(this);
  }

  toggleSelected() {
    this.props.setSelected(!this.props.isSelected);
  }

  render() {
    const { type, direction, isSelected } = this.props;
    let selectorClass = "beat-resolution-selector scale-5";
    if (isSelected) {
      selectorClass += " selected";
    }

    return (
      <div className={selectorClass} key={`${type}_${direction}`} onClick={this.toggleSelected}>
        <div className={`beat-resolution resolution-${direction}`} />
        <p>{_.capitalize(direction)} beat</p>
      </div>
    );
  }
}

ResolutionSelector.propTypes = {
  direction: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  setSelected: PropTypes.func.isRequired,
};

export default ResolutionSelector;