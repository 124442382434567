import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import HTML5Backend from 'react-dnd-html5-backend'
import { DragDropContext } from 'react-dnd'

import BeatBox from './BeatBox'
import BeatPalette from './BeatPalette'
import BeatDragPreview from './BeatDragPreview'
import BeatConfigurationModal from './BeatConfigurationModal'

class _BeatMapEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      beats: this.props.initialBeats,
      selectedBeatId: null,
      configuringBeatId: null,
      configurationMode: null,
      scaleFactor: 3,
    };

    this.addBeat = this.addBeat.bind(this);
    this.updateBeat = this.updateBeat.bind(this);
    this.removeBeat = this.removeBeat.bind(this);
    this.moveBeat = this.moveBeat.bind(this);
    this.findBeat = this.findBeat.bind(this);
    this.selectBeat = this.selectBeat.bind(this);
    this.editBeat = this.editBeat.bind(this);
    this.adjustScaleFactor = this.adjustScaleFactor.bind(this);

    this.showBeatConfiguration = this.showBeatConfiguration.bind(this);
    this.dismissBeatConfiguration = this.dismissBeatConfiguration.bind(this);

    this.updateStory = this.updateStory.bind(this);

    this.renderBeatConfigurationModal = this.renderBeatConfigurationModal.bind(this);
    this.renderBeatPalette = this.renderBeatPalette.bind(this);
  }

  addBeat(newBeat) {
    this.setState({
      beats: this.state.beats.concat(newBeat)
    });
  }

  updateBeat(id, newProps) {
    const { beat, index } = this.findBeat(id);

    const newBeats = this.state.beats.concat();
    newBeats.splice(index, 1, Object.assign({}, beat, newProps));

    this.setState({ beats: newBeats }, this.updateStory);
  }

  removeBeat(id) {
    const { beat, index } = this.findBeat(id);

    const newBeats = this.state.beats.concat();
    newBeats.splice(index, 1);

    this.setState({ beats: newBeats }, this.updateStory);
  }

  selectBeat(id) {
    this.setState({ selectedBeatId: id });
  }

  editBeat(id) {
    if (this.state.configuringBeatId) {
      return;
    }

    this.showBeatConfiguration(id, 'edit');
  }

  showBeatConfiguration(beatId, mode) {
    this.setState({ configuringBeatId: beatId, configurationMode: mode });
  }

  dismissBeatConfiguration() {
    this.setState({ configuringBeatId: null, configurationMode: null });
  }

  updateStory() {
    $.ajax(
      this.props.storyUrl,
      {
        method: 'PATCH',
        dataType: 'json',
        data: JSON.stringify({
          story: {
            beats: this.state.beats
          }
        }),
        processData: false,
        contentType: 'application/json'
      }
    );
  }

  moveBeat(id, toIndex) {
    const { beat, index } = this.findBeat(id);

    const newBeats = this.state.beats.concat();
    newBeats.splice(index, 1);
    newBeats.splice(toIndex, 0, beat);

    this.setState({beats: newBeats}, this.updateStory);
  }

  findBeat(id) {
    const { beats } = this.state;
    const beat = beats.filter(c => c.id === id)[0];

    return {
      beat,
      index: beats.indexOf(beat)
    };
  }

  adjustScaleFactor(amount, callback) {
    const newScaleFactor = this.state.scaleFactor + amount;
    if (newScaleFactor < 1 || newScaleFactor > 10) {
      return;
    }

    this.setState({ scaleFactor: newScaleFactor }, callback);
  }

  renderBeatConfigurationModal() {
    if (!this.state.configuringBeatId) {
      return;
    }

    const { beat: configuringBeat, index } = this.findBeat(this.state.configuringBeatId);

    return <BeatConfigurationModal
      beat={configuringBeat}
      mode={this.state.configurationMode}
      updateBeat={this.updateBeat}
      removeBeat={this.removeBeat}
      dismissBeatConfiguration={this.dismissBeatConfiguration}
    />;
  }

  renderBeatPalette() {
    if (true || this.props.editable) {
      return (
        <BeatPalette
          editable={this.props.editable}
          addBeat={this.addBeat}
          removeBeat={this.removeBeat}
          selectBeat={this.selectBeat}
          showBeatConfiguration={this.showBeatConfiguration}
        />
      );
    } else {
      return null;
    }
  }

  render() {
    return (
      <div className="beat-map-editor">
        {this.renderBeatPalette()}
        <BeatBox
          title={this.props.title}
          description={this.props.description}
          beats={this.state.beats}
          selectedBeatId={this.state.selectedBeatId}
          moveBeat={this.moveBeat}
          findBeat={this.findBeat}
          selectBeat={this.selectBeat}
          editBeat={this.editBeat}
          editable={this.props.editable}
          settingsUrl={this.props.settingsUrl}
          printUrl={this.props.printUrl}
          statusUrl={this.props.statusUrl}
          scaleFactor={this.state.scaleFactor}
          adjustScaleFactor={this.adjustScaleFactor}
        />
        {this.renderBeatConfigurationModal()}
        <BeatDragPreview name="Item" findBeat={this.findBeat} scaleFactor={this.state.scaleFactor} />
      </div>
    );
  }
}

_BeatMapEditor.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  initialBeats: BeatBox.propTypes.beats,
  storyUrl: PropTypes.string.isRequired,
  settingsUrl: PropTypes.string.isRequired,
  printUrl: PropTypes.string.isRequired,
  editable: PropTypes.bool
};

window.BeatMapEditor = DragDropContext(
  Modernizr.touchevents ? reactDndTouchBackend : HTML5Backend)(_BeatMapEditor);

export default BeatMapEditor;