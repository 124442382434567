import PropTypes from 'prop-types'

class BeatMap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: null
    };

    this.updateDimensions = this.updateDimensions.bind(this);
    this.renderBeatWithOffset = this.renderBeatWithOffset.bind(this);
    this.renderBeats = this.renderBeats.bind(this);
  }

  updateDimensions() {
    if (!this.element) {
      return;
    }

    this.setState({ width: this.element.clientWidth });
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  renderBeatWithOffset(beatWithOffset, i) {
    const { beat, xOffset, yOffset } = beatWithOffset;

    return <Beat
      key={beat.id}
      index={i}
      type={beat.type}
      title={(i + 1).toString()}
      id={beat.id}
      description={beat.description}
      resolutions={beat.resolutions}
      transition={beat.transition}
      x={xOffset}
      y={yOffset}
      moveBeat={this.props.moveBeat}
      findBeat={this.props.findBeat}
      selectBeat={this.props.selectBeat}
      editBeat={this.props.editBeat}
      isSelected={this.props.selectedBeatId === beat.id}
      scaleFactor={this.props.scaleFactor}
      _pending={beat._pending}
      editable={this.props.editable}
    />;
  }

  buildBeatRow(beatsWithOffsets) {
    // if the beat row goes into negative territory, compensate by pushing everything down so that the minimum
    // yOffset is 0
    let minYOffset = 0;
    beatsWithOffsets.forEach((beat) => {
      if (minYOffset > beat.yOffset) {
        minYOffset = beat.yOffset;
      }
    });

    return beatsWithOffsets.map((beat) => {
      return {
        beat: beat.beat,
        xOffset: beat.xOffset,
        yOffset: beat.yOffset - minYOffset
      }
    });
  }

  calculateBeatWidth(beat) {
    const beatWidth = 50 + (this.props.scaleFactor * 10);
    const beatMarginWidth = 5;
    const beatResolutionWidth = 20 + (this.props.scaleFactor * 4);
    const beatResolutionsMarginWidth = 10;
    const beatPadding = 10;

    let currentBeatWidth = beatWidth + beatMarginWidth + beatResolutionsMarginWidth + (beatPadding * 2) + 1;
    if (beat.resolutions.length > 0) {
      currentBeatWidth += beatResolutionWidth;
    }

    return currentBeatWidth;
  }

  renderBeats() {
    if (!this.state.width) {
      return;
    }

    const boxWidth = this.state.width;

    let currentYOffset = 0;
    let currentXOffset = 0;
    let currentRow = [];
    let rows = [];

    this.props.beats.forEach((beat, i) => {
      let currentBeatWidth = this.calculateBeatWidth(beat);

      let rightXOffset = currentXOffset + currentBeatWidth;
      if (rightXOffset > boxWidth) {
        rows.push(this.buildBeatRow(currentRow));
        currentRow = [];
        currentXOffset = 0;
        currentYOffset = 0;
        rightXOffset = currentBeatWidth;
      }
      currentXOffset = rightXOffset;

      const beatY = currentYOffset;
      let beatResolutionOffset = 0;

      beat.resolutions.forEach((resolution) => {
        if (resolution.direction === 'down') {
          beatResolutionOffset += 1;
        } else if (resolution.direction === 'up') {
          beatResolutionOffset -= 1;
        }
      });

      if (beatResolutionOffset > 0) {
        currentYOffset += 20;
      } else if (beatResolutionOffset < 0) {
        currentYOffset -= 20;
      }

      currentRow.push({
        beat: beat,
        xOffset: currentXOffset,
        yOffset: beatY
      });
    });

    if (currentRow.length > 0) {
      rows.push(this.buildBeatRow(currentRow));
    }

    let i = -1;
    return rows.map((row) => {
      const beats = row.map((beatWithOffset) => {
        i += 1;
        return this.renderBeatWithOffset(beatWithOffset, i);
      });

      return (
        <div className="beat-row" key={i}>
          {beats}
        </div>
      );
    })
  }

  render () {
    return (
      <div className="beats" ref={(container) => { this.element = container; }}>
        {this.renderBeats()}
      </div>
    );
  }
}

BeatMap.propTypes = {
  beats: PropTypes.arrayOf(
    PropTypes.shape(Beat.jsonProps)
  ).isRequired,
  moveBeat: PropTypes.func.isRequired,
  findBeat: PropTypes.func.isRequired,
  selectBeat: PropTypes.func.isRequired,
  editBeat: PropTypes.func.isRequired,
  selectedBeatId: PropTypes.string,
  editable: PropTypes.bool,
  scaleFactor: PropTypes.number.isRequired,
};

export default BeatMap;