import PropTypes from 'prop-types'

class TransitionSelector extends React.Component {
  constructor(props) {
    super(props);

    this.toggleSelected = this.toggleSelected.bind(this);
  }

  toggleSelected() {
    this.props.setSelected(!this.props.isSelected);
  }

  render() {
    const { type, icon, description, isSelected } = this.props;
    let selectorClass = "beat-resolution-selector scale-5";
    if (isSelected) {
      selectorClass += " selected";
    }

    return (
      <div className={selectorClass} key={`${type}_${icon}`} onClick={this.toggleSelected} data-toggle="tooltip" data-placement="top" data-delay='{"show":1000, "hide":200}' title={description}>
        <div className={`beat-transition transition-${icon}`} />
        <p>{_.startCase(icon)}</p>
      </div>
    );
  }
}

TransitionSelector.propTypes = {
  icon: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  setSelected: PropTypes.func.isRequired,
};

export default TransitionSelector;