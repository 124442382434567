import PropTypes from 'prop-types'

const BEAT_TYPES = [
  {'name': 'anticipation', 'description': 'An expectation of coming procedural success is created.'},
  {'name': 'bringdown', 'description': 'An unpleasant emotional moment, tangential to the narrative.'},
  {'name': 'commentary', 'description': 'The author makes a thematic point.'},
  {'name': 'dramatic', 'description': 'One character engages another, seeking an emotional goal.'},
  {'name': 'gratification', 'description': 'An enjoyable moment, tangential to the narrative.'},
  {'name': 'pipe', 'description': 'Provides information that will matter later.'},
  {'name': 'procedural', 'description': 'A character confronts an external obstacle.'},
  {'name': 'question', 'description': 'Introduces a point of curiosity the audience wants satisfied.'},
  {'name': 'reveal', 'description': 'Answers a question beat, or surprises with new information.'}
];

class BeatPalette extends React.Component {
  render() {
    const items = BEAT_TYPES.map((type, i) => {
      return <BeatPaletteItem
        key={i}
        type={type['name']}
        description={type['description']}
        editable={this.props.editable}
        addBeat={this.props.addBeat}
        showBeatConfiguration={this.props.showBeatConfiguration}
        removeBeat={this.props.removeBeat}
        selectBeat={this.props.selectBeat}
        />
    });

    const paletteTitle = this.props.editable ? 'Drag &amp; Drop' : 'Beat Types';

    return (
      <div className="beat-palette">
        <p className="beat-palette-title" dangerouslySetInnerHTML={{__html: paletteTitle}}></p>
        <hr/>
        {items}
      </div>
    );
  }
}

BeatPalette.propTypes = {
  addBeat: PropTypes.func.isRequired,
  removeBeat: PropTypes.func.isRequired,
  selectBeat: PropTypes.func.isRequired,
  showBeatConfiguration: PropTypes.func.isRequired,
  addingBeat: PropTypes.shape(Beat.jsonProps)
};

export default BeatPalette;