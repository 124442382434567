import PropTypes from 'prop-types'
// import ReactDnD from 'react-dnd'
import { DragSource } from 'react-dnd'

const beatPaletteItemDragSource = {
  canDrag(props) {
      return props.editable;
  },

  beginDrag(props) {
    const newBeat = {
      type: props.type,
      title: "New beat",
      id: genUuid(),
      description: "Drop to add beat here",
      resolutions: [],
      _pending: true
    };

    props.addBeat(newBeat);
    props.selectBeat(null);

    return {
      id: newBeat.id
    };
  },

  endDrag(props, monitor) {
    const { id: droppedId } = monitor.getItem();
    props.showBeatConfiguration(droppedId, 'add');
  }
};

function collectBeatPaletteItemDrag(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
}

class _BeatPaletteItem extends React.Component {
  render() {
    const { connectDragSource, isDragging, type, description } = this.props;

    return connectDragSource(
      <div className={`beat-palette-item ${this.props.editable ? 'editable' : ''}`} data-toggle="tooltip" data-placement="right" data-delay='{"show": 1000, "hide":200}' title={description}>
        <div className={`beat-icon ${type}`} />
        <p>{_.capitalize(type)}</p>
      </div>,
      { dropEffect: 'copy' }
    );
  }
}

_BeatPaletteItem.propTypes = {
  addBeat: PropTypes.func.isRequired,
  removeBeat: PropTypes.func.isRequired,
  selectBeat: PropTypes.func.isRequired,
  showBeatConfiguration: PropTypes.func.isRequired,
};

window.BeatPaletteItem = DragSource("BeatPaletteItem", beatPaletteItemDragSource, collectBeatPaletteItemDrag)(_BeatPaletteItem);

export default BeatPaletteItem;