import PropTypes from 'prop-types'
import Beat from './Beat'
import BeatTrace from './BeatTrace'
import BeatMap from './BeatMap'
import PrintModal from './PrintModal'

class BeatBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      offsetX: 0,
      visibleFraction: 0.01,
      printing: false
    };

    this.unselectBeat = this.unselectBeat.bind(this);
    this.renderScaleButtons = this.renderScaleButtons.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.scaleUp = this.scaleUp.bind(this);
    this.scaleDown = this.scaleDown.bind(this);
    this.updateVisibleFraction = this.updateVisibleFraction.bind(this);
    this.printStory = this.printStory.bind(this);
    this.renderPrintModal = this.renderPrintModal.bind(this);
    this.dismissPrintModal = this.dismissPrintModal.bind(this);
  }

  handleScroll(event) {
    const scrollTop = event.srcElement.scrollTop;
    const scrollHeight = event.srcElement.scrollHeight;

    this.setState({
      offsetX: this.state.offsetX = (scrollTop/scrollHeight)
    });
    this.updateVisibleFraction();
  }

  updateVisibleFraction() {
    const node = ReactDOM.findDOMNode(this);
    window.requestAnimationFrame(() => {
      this.setState({
        visibleFraction: (node.clientHeight/node.scrollHeight)
      });
    });
  }

  componentDidMount() {
    const node = ReactDOM.findDOMNode(this);
    node.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.updateVisibleFraction);
    this.updateVisibleFraction();
  }

  componentWillUnmount() {
    const node = ReactDOM.findDOMNode(this)
    node.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.updateVisibleFraction);
  }

  unselectBeat() {
    this.props.selectBeat(null);
  }

  scaleUp() {
    this.props.adjustScaleFactor(1, this.updateVisibleFraction);
  }

  scaleDown() {
    this.props.adjustScaleFactor(-1, this.updateVisibleFraction);
  }

  renderPrintModal() {
    if (!this.state.printing) {
      return;
    }

    return <PrintModal
      dismissPrintModal={this.dismissPrintModal}
      printUrl={this.props.printUrl}
      statusUrl={this.props.statusUrl}
    />;
  }

  renderScaleButtons() {
    return (
      <div className="beat-box-scale-buttons sits-on-top">
        <button className="btn btn-default btn-sm hide-for-print" onClick={this.scaleUp} disabled={this.props.scaleFactor >= 10}>
          <i className="fa fa-plus" aria-hidden="true" title="Zoom in" />
        </button>
        <button className="btn btn-default btn-sm hide-for-print" onClick={this.scaleDown} disabled={this.props.scaleFactor <= 1}>
          <i className="fa fa-minus" aria-hidden="true" title="Zoom out" />
        </button>
      </div>
    );
  }

  printStory() {
    this.setState({
      printing: 'true'
    });
  }

  dismissPrintModal() {
    this.setState({
      printing: false
    })
  }

  renderActionButtons() {
    console.log(this.props.beats.length);

    return (
      <div className="beat-box-action-buttons sits-on-top hide-for-print">
        <button className="btn btn-default btn-sm" onClick={this.printStory} disabled={this.props.beats.length === 0}>
          <i className='fa fa-file-pdf' aria-hidden="true" title="Create PDF" />
          <span className="sr-only">Print story</span>
        </button>
        <a href={this.props.settingsUrl}
          className={`btn btn-default btn-sm hide-for-print ${this.props.editable ? '' : 'disabled'}`}>
          <i className="fa fa-cogs" aria-hidden="true" title="Edit story settings"/>
          <span className="sr-only">Edit story settings</span>
        </a>
      </div>
    );
  }

  renderTraceBox() {
    return (
      <BeatTrace
        beats={this.props.beats}
        visibleSectionStart={this.state.offsetX}
        visibleSectionWidth={this.state.visibleFraction}
      />
    );
  }

  render () {
    return (
      <div className="beat-box" onClick={this.unselectBeat}>
        <div className="beat-map-header">
          <h3 className="beat-map-title">
            {this.props.title}
          </h3>

          <h5 className="beat-map-description">
            {this.props.description}
          </h5>
        </div>

        {this.renderScaleButtons()}
        {this.renderTraceBox()}
        {this.renderActionButtons()}
        <BeatMap
          beats={this.props.beats}
          moveBeat={this.props.moveBeat}
          findBeat={this.props.findBeat}
          selectBeat={this.props.selectBeat}
          editBeat={this.props.editBeat}
          selectedBeatId={this.props.selectedBeatId}
          editable={this.props.editable}
          scaleFactor={this.props.scaleFactor}
        />
        {this.renderPrintModal()}
      </div>
    );
  }
}

BeatBox.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  beats: PropTypes.arrayOf(
    PropTypes.shape(Beat.jsonProps)
  ).isRequired,
  moveBeat: PropTypes.func.isRequired,
  findBeat: PropTypes.func.isRequired,
  selectBeat: PropTypes.func.isRequired,
  editBeat: PropTypes.func.isRequired,
  selectedBeatId: PropTypes.string,
  editable: PropTypes.bool,
  settingsUrl: PropTypes.string.isRequired,
  printUrl: PropTypes.string.isRequired,
  statusUrl: PropTypes.string.isRequired,
  scaleFactor: PropTypes.number.isRequired,
  adjustScaleFactor: PropTypes.func.isRequired,
};

export default BeatBox;