import PropTypes from 'prop-types'

class BeatTrace extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: null,
      traceWidth: 400 - (2 * 10),
      initialX: 10,
      resolutionOffset: 2
    };
  }

  renderTrace() {
    let currentBeatSize = this.state.traceWidth/this.props.beats.length;
    let currentY = null;
    let currentX = null;

    let points = '';
    let minY = null;
    let maxY = null;

    do { // looping until we find a vertical offset where the line fits in the box
      points = '';
      minY = 33;
      maxY = 33;
      currentY = 33;
      currentX = this.state.initialX - currentBeatSize;

      this.props.beats.forEach((beat, i) => {
        let beatResolutionOffset = 0;
        beat.resolutions.forEach((resolution) => {
          if (resolution.direction === 'down') {
            beatResolutionOffset += this.state.resolutionOffset;
          } else if (resolution.direction === 'up') {
            beatResolutionOffset -= this.state.resolutionOffset;
          }
        });
        currentX += currentBeatSize;
        currentY += beatResolutionOffset;
        if (currentY < minY) {minY = currentY}
        if (currentY > maxY) {maxY = currentY}
        points += `${currentX},${currentY} `
      } );

      // if we are rendering outside the trace box, reduce the vertical
      // offset and try again.
      if (minY <= 0 || maxY >= 66) {
        this.state.resolutionOffset *= 0.75;
      }
    } while(minY <= 0 || maxY >= 66)

    return (
      <svg width="100%">
        <polyline
          points={points}
          stroke="#333"
          strokeWidth="4"
          fill="none" />
        </svg>
    );
  }

  renderVisibleSection() {
      return (
        <div className='beat-trace-visible-section'
          style={{
            left: (this.state.traceWidth * this.props.visibleSectionStart) + this.state.initialX,
            width: (this.state.traceWidth * this.props.visibleSectionWidth)
          }}>
        </div>
      );
  }

  render () {
    return (
      <div className="beat-box-trace-box sits-on-top hide-for-print" ref={(container) => { this.element = container; }}>
        {this.renderTrace()}
        {this.renderVisibleSection()}
      </div>
    );
  }
}

BeatTrace.propTypes = {
  beats: PropTypes.arrayOf(
    PropTypes.shape(Beat.jsonProps)
  ).isRequired,
  visibleSectionStart: PropTypes.number.isRequired,
  visibleSectionWidth: PropTypes.number.isRequired
};

export default BeatTrace;