import PropTypes from 'prop-types'

class PrintModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      beatSize: 2,
      status: 'ready',
      pct_complete: 0,
      canceled: false,
      pdf_url: null
    };

    this.cancel = this.cancel.bind(this);
    this.print = this.print.bind(this);
    this.monitorPrintStatus = this.monitorPrintStatus.bind(this);
  }

  componentWillUnmount() {
    if(this.timerHandle) {
      clearTimeout(this.timerHandle);
    }
  }

  cancel() {
    this.setState({canceled: true}, () => this.props.dismissPrintModal());
  }

  
  print() {
    this.setState({canceled: false})
  	$.ajax(
      this.props.printUrl,
      {
        method: 'GET',
        dataType: 'json',
        contentType: 'application/json',
        success: this.monitorPrintStatus
      }
    );
  }

  monitorPrintStatus() {
    this.timerHandle = setTimeout(() => {
      console.log('here 1');
      $.ajax(
        this.props.statusUrl,
        {
          method: 'GET',
          dataType: 'json',
          contentType: 'application/json',
          success: (result) => {
            console.log(result);
            this.setState({
              status: result.status,
              pct_complete: result.pct_complete,
              pdf_url: result.pdf_url
            });
            if(result.status != 'complete' && !this.state.canceled) {
              this.monitorPrintStatus();
            }
          }
        }
      )
    }, 250);
  }

  renderStatusString() {
    let statusString = _.capitalize(this.state.status);

    if(this.state.status === 'working') {
      statusString += ` (${this.state.pct_complete}% rendered)`
    }

    return statusString;
  }

  render() {
    return (
      <div className="modal fade in" style={{display: "block"}}>
        <div className="modal-dialog modal-resolution-selector" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Generate PDF</h4>
            </div>
            <div className="modal-body">
              <p>Status: {this.renderStatusString()}</p>
              <div className="progress progress-animated">
                <div className="progress-bar progress-bar-striped" role="progressbar" aria-valuenow={this.state.pct_complete} aria-valuemin="0" aria-valuemax="100" style={{width: `${this.state.pct_complete}%`}}>
                  {this.state.pct_complete}%
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="row">
                <div className="col-sm-3 text-sm-left">
                  
                </div>
                <div className="col-sm-9">
                  <ul className="list-inline">
                    {this.state.status === 'ready' &&
                    <li className="list-inline-item">
                      <button type="button" className="btn btn-secondary btn-sm" onClick={this.cancel}>Cancel</button>
                    </li>}
                    <li className="list-inline-item">
                      {this.state.status === 'ready' &&
                      <button type="button" className="btn btn-primary btn-sm" onClick={this.print} title="Start generating pdf">Start</button>}
                      {this.state.status === 'working' &&
                      <button type="button" className="btn btn-primary btn-sm" onClick={this.cancel}>Cancel</button>}
                      {this.state.status === 'complete' &&
                      <a className="btn btn-primary btn-sm" href={this.state.pdf_url} target='_blank'>
                        <i className='fa fa-file-download' />
                        &nbsp;&nbsp;
                        Download PDF
                      </a>}
                    </li>
                    {this.state.status === 'complete' &&
                    <li className='list-inline-item'>
                      <button type="button" className="btn btn-secondary btn-sm" onClick={this.cancel}>Close</button>
                    </li>}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PrintModal.propTypes = {
  dismissPrintModal: PropTypes.func.isRequired,
  printUrl: PropTypes.string.isRequired,
  statusUrl: PropTypes.string.isRequired
};

export default PrintModal;